import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import store from "../MobX";

const GIFT_SUM_ARR = [10000, 25000, 50000, 75000, 100000];
const DISCOUNTED_KIT_PIN = "discounted-kit";

/**
 * @description Проверка, меньше ли ширина экрана, чем переданное значение
 * @param {number} startWidth Ширина, которую сравнивать с шириной экрана
 * @returns {boolean}
 */
const useCheckWidth = (startWidth) => {
  const [width, setWidth] = useState(startWidth);
  const [isSmall, setIsSmall] = useState(store.windowWidth < startWidth);
  useEffect(() => {
    setIsSmall(store.windowWidth < width);
  }, [store.windowWidth, width]);

  return isSmall;
};

const useServiceSEO = (defaultTitle, defaultDescription, defaultKeywords) => {
  const [title, setTitle] = useState(defaultTitle);
  const [description, setDescription] = useState(defaultDescription);
  const [keywords, setKeywords] = useState(defaultKeywords);
  const location = useLocation();

  useEffect(() => {
    const seo = store.serviceSEO[location.pathname];

    if (seo) {
      if (seo.title) setTitle(seo.title);
      if (seo.description) setDescription(seo.description);
      if (seo.keywords) setKeywords(seo.keywords);
    }
  }, [store.serviceSEO, location]);

  return [title, description, keywords];
};

const resizeImage = (img, quality) => {
  const canvas = document.createElement("canvas");

  const width = img.width;
  const height = img.height;

  // resize the canvas and draw the image data into it
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height);

  return canvas.toDataURL("image/jpeg", quality); // get the data from canvas as 70% JPG (can be also PNG, etc.)
};

const getPinFromSize = (size) => {
  if (!size) return;
  if (size.isDiscountedKit) return DISCOUNTED_KIT_PIN;
  if (size?.deliverypin && size?.deliverypin !== "0") return size.deliverypin;
  return size?.autodeliverypin;
};

export {
  useCheckWidth,
  useServiceSEO,
  resizeImage,
  getPinFromSize,
  GIFT_SUM_ARR,
  DISCOUNTED_KIT_PIN,
};
