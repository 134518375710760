import api from "./jsCommon/api";
import { sizeTableData } from "./jsCommon/constants";

const fetchJsFromCDN = (src, externals = []) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.setAttribute("src", src);
    script.addEventListener("load", () => {
      resolve(
        externals.map((key) => {
          const ext = window[key];
          typeof ext === "undefined" &&
            console.warn(`No external named '${key}' in window`);
          return ext;
        })
      );
    });
    script.addEventListener("error", reject);
    document.body.appendChild(script);
  });
};

const decodeString = (str) => {
  return new DOMParser().parseFromString(str, "text/html").documentElement
    .textContent;
};
const decodeStringHTML = (str) => {
  return new DOMParser().parseFromString(str, "text/html").documentElement
    .innerHTML;
};

const returnSizeTableData = (brand, sSex, sType) => {
  if (sizeTableData[brand] && sizeTableData[brand][sType]) {
    if (sizeTableData[brand][sType].withSex) {
      if (sSex) return sizeTableData[brand][sType][sSex];
      else return undefined;
    } else {
      return sizeTableData[brand][sType];
    }
  } else {
    return undefined;
  }
};

const sendNotFoundPath = (path) => {
  api
    .postNotFoundInfo({ path })
    .then((result) => {})
    .catch((err) => {
      console.error("err :>> ", err);
    });
};

const processEditorJsHTML = (editJSHTML) => {
  if (!editJSHTML) return;
  return editJSHTML.join(
    editJSHTML?.every((str) => str.startsWith("<p")) ? "" : "\n<br/>"
  );
};

const parseEditorJsToDesc = (EditJSHTML) => {
  if (!EditJSHTML) return;
  const parsDesc = [];
  const parsDescSecond = [];
  const cont = document.createElement("div");
  cont.innerHTML = EditJSHTML.join();
  let firstBlock = true;
  let secondBlock = false;
  let haveTitle = false;
  cont.childNodes.forEach((el) => {
    const data = {};
    switch (el.localName) {
      case "h2":
        data.text = el.textContent;
        data.header = true;
        if (!firstBlock && !secondBlock) secondBlock = true;

        if (firstBlock) {
          haveTitle = true;
          firstBlock = false;
          parsDesc.push(
            <h1
              dangerouslySetInnerHTML={{
                __html: el.textContent,
              }}
              className="h2"
            ></h1>
          );
        } else {
          parsDescSecond.push(<br />);
          parsDescSecond.push(
            <h2
              dangerouslySetInnerHTML={{
                __html: el.textContent,
              }}
            ></h2>
          );
        }
        break;
      case "h3":
        data.text = el.textContent;
        data.header = true;
        if (!firstBlock && !secondBlock) secondBlock = true;

        if (firstBlock) {
          haveTitle = true;
          firstBlock = false;
          parsDesc.push(
            <h1
              dangerouslySetInnerHTML={{
                __html: el.textContent,
              }}
              className="h2"
            ></h1>
          );
        } else {
          parsDescSecond.push(<br />);
          parsDescSecond.push(
            <h3
              dangerouslySetInnerHTML={{
                __html: el.textContent,
              }}
            ></h3>
          );
        }
        break;
      case "p":
        data.text = el.textContent;
        const toElement = el.innerHTML ? el.innerHTML : el.textContent;
        data.paragraph = true;
        if (!secondBlock) {
          parsDesc.push(
            <p
              dangerouslySetInnerHTML={{
                __html: toElement,
              }}
            ></p>
          );
          firstBlock = false;
        }
        if (secondBlock) {
          parsDescSecond.push(
            <p
              dangerouslySetInnerHTML={{
                __html: toElement,
              }}
            ></p>
          );
        }
        break;
      case "ul":
        const lis = [];
        data.text = [];
        el.childNodes.forEach((li) => {
          const text = li.textContent;
          if (text) {
            data.text.push(text);
            lis.push(text);
          }
        });
        parsDesc.push(<ul>{lis}</ul>);
        data.list = true;
        break;
      case "ol":
        const lisOl = [];
        data.text = [];
        el.childNodes.forEach((li) => {
          const text = li.innerHTML ? li.innerHTML : li.textContent;
          if (text) {
            data.text.push(text);
            lisOl.push(
              <li
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              ></li>
            );
          }
        });
        parsDesc.push(<ol>{lisOl}</ol>);
        data.list = true;
        break;

      default:
        break;
    }
    // parsDesc.push(data)
  });
  return [parsDesc, parsDescSecond, haveTitle];
};

const polToLocalize = {
  MAN: "Мужское",
  WOMAN: "Женское",
  JUNIOR: "Детское",
  "": "Унисекс",
  undefined: "Все",
};

const getNameActiveFilter = (activeFilters, name, specName) => {
  if (name === "pol") return polToLocalize[activeFilters[name]];
  if (!activeFilters[name]) return [""];
  else if (Array.isArray(activeFilters[name])) {
    return activeFilters[name].map((elem, i) => {
      let nameVariation = elem;

      if (name === "perechenRazmerov")
        nameVariation = nameVariation.split("-")[1];
      else if (name === "spec") {
        let specFilterSplit = (nameVariation = nameVariation.split("-"));
        if (specFilterSplit[0] === specName) {
          nameVariation = specFilterSplit[1];
        } else nameVariation = null;
      }

      return nameVariation;
    });
  } else {
    let nameVariation = activeFilters[name];

    if (name === "god")
      nameVariation = `20${activeFilters[name].$gte} - 20${activeFilters[name].$lte}`;
    else if (name === "cenaSoSkidkoy")
      nameVariation = `${activeFilters[
        name
      ].$gte?.toLocaleString()} - ${activeFilters[
        name
      ].$lte?.toLocaleString()}`;
    else if (name === "skidka")
      nameVariation = activeFilters[name] === "true" ? "Со скидкой" : "";

    return nameVariation;
  }
};

/**
 *
 * @param {string} text
 * @param {()=>void} onSuccses
 * @param {()=>void} onError
 * @returns
 */
function fallbackCopyTextToClipboard(text, onSuccses, onError) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    if (successful) onSuccses();
    else onError();
  } catch (err) {
    onError();
  }

  document.body.removeChild(textArea);
}

/**
 *
 * @param {string} text
 * @param {()=>void} onSuccses
 * @param {()=>void} onError
 * @returns
 */
function copyTextToClipboard(text, onSuccses, onError) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, onSuccses, onError);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      if (onSuccses) onSuccses();
    },
    function (err) {
      if (onError) onError();
    }
  );
}

export {
  fetchJsFromCDN,
  decodeString,
  decodeStringHTML,
  returnSizeTableData,
  sendNotFoundPath,
  processEditorJsHTML,
  parseEditorJsToDesc,
  getNameActiveFilter,
  copyTextToClipboard,
};
