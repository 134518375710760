import { SERVER_URL } from "./constants";
class Api {
  constructor() {}

  fetchConstructor(path, method, body) {
    const settings = {
      method: method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (body) settings.body = JSON.stringify(body);
    return fetch(path, settings).then((res) => {
      let json = res.json();
      if (res.ok) {
        return json;
      }
      return json.then((err) => {
        throw err;
      });
    });
  }

  getRedLine() {
    return this.fetchConstructor(SERVER_URL + "/site-settings/red-line", "GET");
  }

  getMainSEO() {
    return this.fetchConstructor(SERVER_URL + "/main-page", "GET");
  }
  getServiceSEO() {
    return this.fetchConstructor(SERVER_URL + "/seo-service", "GET");
  }
  getServiceSort() {
    return this.fetchConstructor(SERVER_URL + "/sort-service", "GET");
  }
  getCategories() {
    return this.fetchConstructor(SERVER_URL + "/categories", "GET");
  }
  getCategoriesInMainPage() {
    return this.fetchConstructor(SERVER_URL + "/categories-main", "GET");
  }
  getCategoriesAfterBanner() {
    return this.fetchConstructor(SERVER_URL + "/categories-banner", "GET");
  }
  getCategoryDesc(name) {
    return this.fetchConstructor(SERVER_URL + "/category/description", "POST", {
      name,
    });
  }
  getGiftPage(promo) {
    return this.fetchConstructor(SERVER_URL + "/gift-page/" + promo, "GET");
  }
  getCategoryAdvice(name) {
    return this.fetchConstructor(SERVER_URL + "/category/advice", "POST", {
      name,
    });
  }
  getSales() {
    return this.fetchConstructor(SERVER_URL + "/sale", "GET");
  }
  getSale(slug) {
    return this.fetchConstructor(SERVER_URL + "/sale/" + slug, "GET");
  }

  getBanners() {
    return this.fetchConstructor(SERVER_URL + "/banners", "GET");
  }

  getBrands() {
    return this.fetchConstructor(SERVER_URL + "/brand", "GET");
  }
  getBrand(name) {
    return this.fetchConstructor(SERVER_URL + "/brand/" + name, "GET");
  }

  getPins() {
    return this.fetchConstructor(SERVER_URL + "/pin", "GET");
  }
  getPin(code) {
    return this.fetchConstructor(SERVER_URL + "/pin/" + code, "GET");
  }
  getGeneratePage(slug) {
    return this.fetchConstructor(SERVER_URL + "/g-page/" + slug, "GET");
  }
  getGeneratePages() {
    return this.fetchConstructor(SERVER_URL + "/g-page", "GET");
  }

  postFilterProduct(data) {
    return this.fetchConstructor(SERVER_URL + "/filters/product", "POST", data);
  }

  postFilterCompatibility(data) {
    return this.fetchConstructor(
      SERVER_URL + "/filters/product-compatibility",
      "POST",
      data
    );
  }

  postFilterProductSize(data) {
    return this.fetchConstructor(
      SERVER_URL + "/filters/product-size",
      "POST",
      data
    );
  }

  postProducts(data) {
    return this.fetchConstructor(SERVER_URL + "/products", "POST", data);
  }
  postProductsRandom(data) {
    return this.fetchConstructor(SERVER_URL + "/r-products", "POST", data);
  }

  getProduct(slug) {
    return this.fetchConstructor(SERVER_URL + "/product/" + slug, "GET");
  }

  getProductRealImages(slug) {
    return this.fetchConstructor(
      SERVER_URL + "/product/" + slug + "/real-image",
      "GET"
    );
  }

  getProductReview(slug, data) {
    return this.fetchConstructor(
      SERVER_URL + "/product-review/" + slug,
      "POST",
      data
    );
  }

  checkCanSendProductReview(slug, data) {
    return this.fetchConstructor(
      SERVER_URL + "/product-review-check/" + slug,
      "POST",
      data
    );
  }

  postProductReview(review) {
    return this.fetchConstructor(
      SERVER_URL + "/product-review",
      "POST",
      review
    );
  }

  getProductsBySlugs(slugs) {
    return this.fetchConstructor(SERVER_URL + "/product-slugs", "POST", slugs);
  }
  getProductColors(arr) {
    return this.fetchConstructor(SERVER_URL + "/product-colors", "POST", arr);
  }
  getProductSizes(arr) {
    return this.fetchConstructor(SERVER_URL + "/product-sizes", "POST", arr);
  }
  getProductTovar(arr) {
    return this.fetchConstructor(SERVER_URL + "/product-tovar", "POST", arr);
  }

  getProductArticle(filter) {
    return this.fetchConstructor(
      SERVER_URL + "/product-articles",
      "POST",
      filter
    );
  }

  processProductsInCart(products) {
    return this.fetchConstructor(
      SERVER_URL + "/check-cart-products",
      "POST",
      products
    );
  }

  getProductSizeCompatibility(oems) {
    return this.fetchConstructor(
      SERVER_URL + "/product-size-compatibility",
      "POST",
      oems
    );
  }
  getCrossProds(data) {
    return this.fetchConstructor(SERVER_URL + "/c-products", "POST", data);
  }
  getUpProds(data) {
    return this.fetchConstructor(SERVER_URL + "/u-products", "POST", data);
  }

  getPersonalPage(uid) {
    return this.fetchConstructor(SERVER_URL + "/personal/" + uid, "GET");
  }

  getProductImageMC(article) {
    return this.fetchConstructor(
      SERVER_URL + "/product-image-mc/" + article,
      "GET"
    );
  }

  createPreOrder(data) {
    return this.fetchConstructor(
      SERVER_URL + "/create-pre-order",
      "POST",
      data
    );
  }
  createOrder(data) {
    return this.fetchConstructor(SERVER_URL + "/create-order", "POST", data);
  }
  getOrderFinish(id) {
    return this.fetchConstructor(SERVER_URL + "/get-order/" + id, "GET");
  }
  postFindCheaper(data) {
    return this.fetchConstructor(SERVER_URL + "/find-cheap", "POST", data);
  }

  postCallAvailability(data) {
    return this.fetchConstructor(
      SERVER_URL + "/query-availability",
      "POST",
      data
    );
  }

  getPromo(code) {
    return this.fetchConstructor(SERVER_URL + "/promocode", "POST", { code });
  }

  //Пользователи
  checkEmail(email) {
    return this.fetchConstructor(SERVER_URL + "/check-email/" + email, "GET");
  }
  getUser() {
    return this.fetchConstructor(SERVER_URL + "/user-data", "GET");
  }
  putUser(data) {
    return this.fetchConstructor(SERVER_URL + "/ch-user-data", "PUT", data);
  }
  putPass(data) {
    return this.fetchConstructor(SERVER_URL + "/ch-pass", "PUT", data);
  }
  getOrders(page, withActive) {
    return this.fetchConstructor(
      SERVER_URL +
        `/order-hn?page=${page}${withActive ? "&withActive=true" : ""}`,
      "GET"
    );
  }
  getOrdersCount() {
    return this.fetchConstructor(SERVER_URL + "/order-c", "GET");
  }
  getOrdersStatusInfo() {
    return this.fetchConstructor(SERVER_URL + "/order-s", "GET");
  }
  getOrder(id) {
    return this.fetchConstructor(SERVER_URL + "/order-h/" + id, "GET");
  }
  getBonusH(page) {
    return this.fetchConstructor(SERVER_URL + "/bonus-h?page=" + page, "GET");
  }
  getBonusHF(page) {
    return this.fetchConstructor(SERVER_URL + "/bonus-hf?page=" + page, "GET");
  }

  subscr(data) {
    return this.fetchConstructor(SERVER_URL + "/subscr", "POST", data);
  }

  login(data) {
    return this.fetchConstructor(SERVER_URL + "/login", "POST", data);
  }
  logout() {
    return this.fetchConstructor(SERVER_URL + "/logout", "GET");
  }
  registration(data) {
    return this.fetchConstructor(SERVER_URL + "/registration", "POST", data);
  }
  restorePass(data) {
    return this.fetchConstructor(SERVER_URL + "/restore-pass", "POST", data);
  }

  sendQuestion(data) {
    return this.fetchConstructor(SERVER_URL + "/send-question", "POST", data);
  }
  getCity() {
    return this.fetchConstructor("https://geolocation-db.com/json", "GET");
  }

  postNotFoundInfo(data) {
    return this.fetchConstructor(SERVER_URL + "/nf-info", "POST", data);
  }

  postShareCart(data) {
    return this.fetchConstructor(SERVER_URL + "/cart", "POST", data);
  }

  getShareCart(code) {
    return this.fetchConstructor(SERVER_URL + "/cart/" + code, "GET");
  }
}

const api = new Api();

export default api;
